<template>
  <div class="profile-form__header">
    <div>
      <h2 class="profile-form__title">
        <slot />
        <EditButton v-if="editable && (!editing || isMobile)" @click="emit('edit')" />
      </h2>
      <div v-if="!!subtitle" class="profile-form__subtitle">{{ subtitle }}</div>
      <div v-if="error" class="profile-form__error">{{ error }}</div>
    </div>
    <div v-if="hint" class="profile-form__header-hint">{{ hint }}</div>
    <ProfileFormActions v-if="editActions && editing && !isMobile" @cancel="emit('cancel')" @submit="emit('submit')" :class="{ pending }" />
  </div>
</template>

<script setup>
import ProfileFormActions from '@/components/user/profile/forms/ProfileFormActions'
import EditButton from '@/components/user/profile/EditButton'

const props = defineProps({
  editable: Boolean,
  editing: {
    type: Boolean,
    default: true
  },
  pending: Boolean,
  subtitle: String,
  hint: String,
  error: String,
  editActions: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['cancel', 'submit', 'edit'])

const { isMobile } = useBreakpoints()
</script>

<style scoped lang="scss">
.profile-form {
  &__header {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    @media (max-width: 920px) {
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__title {
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    color: var(--color-bg-dark);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    @media (max-width: 920px) {
      font-size: 20px;
      line-height: 24px;
      justify-content: space-between;
    }
  }
  &__header-hint {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-elements-secondary);
  }
  &__subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-elements-secondary);
    margin-top: 16px;
  }
  &__error {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--color-error);
    margin-top: 8px;
  }
}
</style>
