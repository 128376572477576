<template>
  <ProfileField :single-col="singleCol">
    <button class="profile-add" v-bind="$attrs" type="button">
      <IconPlus class="icon" />
      <span><slot /></span>
    </button>
  </ProfileField>
</template>

<script>
export default { inheritAttrs: false }
</script>

<script setup>
import ProfileField from '@/components/user/profile/ProfileField'
import { IconPlus } from '@tabler/icons-vue'

const props = defineProps({
  singleCol: Boolean
})
</script>

<style scoped lang="scss">
.profile-add {
  padding: 16px 0;
  border-top: 1px solid var(--color-separator);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-elements-secondary);
  display: flex;
  align-items: center;
  gap: 10px;
  transition: .2s color;
  width: 100%;
  .icon {
    width: 24px;
    height: 24px;
    color: var(--color-elements-tertiary);
    transition: .2s color;
  }
  &:hover {
    color: var(--color-elements-primary);
    .icon {
      color: var(--color-elements-secondary);
    }
  }
  @media (max-width: 920px) {
    border-top: none;
    transform: translateY(-25px);
  }
}
</style>
