<template>
  <AtomInput
    type="select"
    :create-option="createOption"
    :append-new-option="false"
    @option="onCreate"
    v-model="model"
    :options="availableOptions"
  />
</template>

<script setup>
import AtomInput from '@/components/common/AtomInput'

const props = defineProps({
  id: Number,
  text: String,
  options: Array,
  selected: {
    type: Array,
    default: []
  },
  createOption: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['update:id', 'update:text', 'new'])

const idProp = computed({
  get: () => props.id,
  set: val => emit('update:id', val)
})
const nameProp = computed({
  get: () => props.text,
  set: val => emit('update:text', val)
})
const model = ref()

watch(idProp, id => (model.value = id))
watch(nameProp, name => (model.value = idProp.value ?? name), { immediate: true })
watch(model, value => {
  if (typeof value === 'number') {
    idProp.value = value
    nameProp.value = undefined
  } else {
    idProp.value = undefined
    nameProp.value = value
  }
})

function onCreate (skill) {
  if (props.selected.some(s => areSame(s, { name: skill }))) return nextTick(() => (model.value = undefined))

  const existing = props.options.find(o => areSame(o, { name: skill }))
  if (existing) return nextTick(() => (model.value = existing.id))

  emit('new', skill)
}

const areSame = (s1, s2) => (s1.skill_id ?? s1.id ?? s1) === (s2.skill_id ?? s2.id ?? s2) || (s1.skill_name ?? s1.name)?.toLowerCase().trim() === (s2.skill_name ?? s2.name)?.toLowerCase().trim()
const availableOptions = computed(() => props.options.filter(
  s => areSame(s, { id: idProp.value, name: nameProp.value }) ||
    !props.selected.some(sel => areSame(s, sel))
))
</script>

<style scoped lang="scss">

</style>
