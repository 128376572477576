<template>
  <PopperMenu
    offset-distance="10"
    placement="bottom"
    :disabled="!editable || !isMobile || !avatarUrl"
    style="max-width: 100%; box-sizing: content-box;"
  >
    <div class="avatar-upload-wrap" :class="{ 'avatar-upload-wrap--has-image': !!avatarUrl || !editable, 'avatar-upload-wrap--input': !!input }">
      <div class="avatar-upload" :style="{backgroundImage: `url('${avatarUrl}')`}">
        <AvatarPlaceholder class="avatar-upload__placeholder" :data="!image ? data : {}" v-if="!avatarUrl" :medium="isMobile" />
        <input v-if="editable" ref="inputRef" type="file" id="uploadphoto__input" class="uploadphoto__input" accept="image/png,image/jpeg" @change="showCropModal">
      </div>

      <div class="avatar-upload-controls" v-if="editable && !isMobile">
        <label for="uploadphoto__input" class="avatar-upload-controls__action" :title="addPhotoTitle">
          <IconCameraPlus />
        </label>
        <button class="avatar-upload-controls__action" @click="remove" v-if="!!avatarUrl" title="Удалить фото">
          <IconTrash />
        </button>
      </div>
      <button class="avatar-upload-wrap__plus" :style="{ backgroundColor: !image && data?.avatar_color }"><IconPlus class="icon" /></button>
    </div>
    <template #content>
      <PopperMenuItem @click="inputRef?.click?.()"><IconCameraPlus class="icon" />{{ addPhotoTitle }}</PopperMenuItem>
      <PopperMenuItem @click="remove" v-if="!!avatarUrl"><IconTrash class="icon" />Удалить фото</PopperMenuItem>
    </template>
  </PopperMenu>
  <div v-if="error" class="error">{{ error }}</div>
  <BaseButton class="avatar-upload__button" v-if="button && editable && !avatarUrl" transparent small stretch @click="inputRef?.click()"><IconPhoto class="icon" />{{ typeof button === 'string' ? button : 'Загрузить фото' }}</BaseButton>
  <BaseModal class="modal-wrap" close-button :shown="cropModalShown" @close="cropModalShown = false">
    <h1 class="modal-title">{{ !image ? 'Загрузить аватар' : 'Загрузить фото' }}</h1>

    <Cropper class="cropper" :key="cropperID" :src="fileBlobURL" :stencil-props="{ aspectRatio: aspectRatio ?? 1 }" @change="change"/>

    <div class="button-row">
      <BaseButton transparent @click="cropModalShown = false">Отмена</BaseButton>
      <BaseButton color-primary @click="upload" :class="{ pending: loading }">Загрузить</BaseButton>
    </div>
  </BaseModal>
</template>

<script setup>
import useForm from '~/composables/useForm'
import { storeAvatar, storeFile } from '@/api/user'
import BaseModal from '@/components/common/BaseModal'
import { Cropper } from 'vue-advanced-cropper'
import { IconCameraPlus, IconTrash, IconPhoto, IconPlus } from '@tabler/icons-vue'
import AvatarPlaceholder from '@/components/common/AvatarPlaceholder'
import BaseButton from '@/components/common/BaseButton'

const MAX_FILE_SIZE_KB = 5000

const props = defineProps(['user', 'modelValue', 'input', 'button', 'isEditable', 'server', 'image', 'aspectRatio', 'uploaded', 'error'])
const emit = defineEmits(['update:modelValue'])

const { isMobile } = useBreakpoints(props)

const inputRef = ref()
const user = inject('user')
const profileEditable = inject('profile.editable')
const editable = computed(() => typeof props.isEditable === 'boolean' ? props.isEditable : profileEditable.value)
const updateUserInfo = inject('updateUserInfo')
const file = ref(null)
const fileBlobURL = ref('')
const cropModalShown = ref(false)
const cropperID = ref(1)
const data = computed(() => props.user?.personal ?? props.user ?? user.value?.personal)

const avatarUrl = computed(() => {
  if (props.input) {
    if (props.image) return uploaded.value?.url
    return model.value ? URL.createObjectURL(model.value) : null
  }
  return data.value?.avatar_url
})
const addPhotoTitle = computed(() => avatarUrl.value ? 'Заменить фото' : 'Добавить фото')

const model = computed({
  get: () => props.modelValue,
  set: value => emit('update:modelValue', value)
})
const uploaded = ref()
watch(uploaded, file => model.value = file?.id ?? null)
watchImmediate(() => props.uploaded, file => file && (uploaded.value = file))

const {
  form,
  errors,
  submit,
  loading
} = useForm(data => props.image ? storeFile(data.file) : storeAvatar(data), data => props.image ? (uploaded.value = data) : updateUserInfo())

const _error = ref()
const error = computed({
  get: () => _error.value ?? errors.value.avatar ?? props.error,
  set: value => _error.value = value
})

function showCropModal (event) {
  error.value = null
  file.value = event.target.files[0]
  event.target.value = null

  if (file.value.size > MAX_FILE_SIZE_KB * 1024) return error.value = `Размер файла не должен превышать ${MAX_FILE_SIZE_KB} КБ`

  const reader = new FileReader()
  reader.addEventListener('load', () => {
    fileBlobURL.value = reader.result

    cropperID.value++
    cropModalShown.value = true
  })
  reader.readAsDataURL(file.value)
}

let canvas

function change (event) {
  canvas = event.canvas
}

function upload () {
  loading.value = true
  canvas.toBlob(async blob => {
    if (props.input) {
      if (props.image) form.value.file = blob
      else model.value = blob
    }
    else form.value.avatar = blob
    if (!props.input || props.image) await submit()
    cropModalShown.value = false
    loading.value = false
  })
}

async function remove () {
  if (props.input) {
    uploaded.value = null
    if (model.value) model.value = null
  }
  else {
    form.value.avatar = null
    await submit()
  }
  cropModalShown.value = false
}
</script>

<style src="vue-advanced-cropper/dist/style.css"/>

<style scoped lang="scss">
.avatar-upload-wrap {
  position: relative;
  width: 300px;
  aspect-ratio: 1;
  max-width: 100%;

  border-radius: 16px;
  overflow: hidden;

  @media (max-width: 920px) {
    width: 77px;
    border-radius: 8px;
    cursor: pointer;
    &--input {
      width: 136px;
    }
    padding-bottom: 20px;
    box-sizing: content-box;
  }

  &__plus {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 32px;
    height: 32px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--color-primary);
    border: 4px solid var(--color-bg);
    color: var(--color-bg);
    box-sizing: content-box;
    .icon {
      width: 24px;
      height: 24px;
    }
    @media (max-width: 920px) {
      display: flex;
    }
  }

  &--has-image {
    @media (max-width: 920px) {
      .uploadphoto__input {
        display: none;
      }
    }
    .avatar-upload-wrap__plus {
      display: none;
    }
    padding-bottom: 0;
  }
}

.avatar-upload {
  width: 100%;
  height: 100%;

  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  &__placeholder {
    height: 100%;
  }
  &__button {
    margin-top: 24px;
    @media (max-width: 920px) {
      margin-top: 8px;
    }
  }
}

.avatar-upload-controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 10px;
  opacity: 0;
  transition: .4s opacity;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 140px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.48), rgba(0, 0, 0, 0));
    pointer-events: none;
  }
  &__action {
    position: relative;
    color: #FFFFFF;
    transition: .2s color;
    cursor: pointer;
    &:hover {
      color: var(--color-primary);
    }
  }
}
.avatar-upload-wrap:hover .avatar-upload-controls {
  opacity: 1;
}

.avatar-upload-remove {
  position: absolute;
  top: -8px;
  right: -8px;
  border: none;
  border-radius: 18px;
  background: var(--color-secondary);
  width: 36px;
  height: 36px;
  opacity: 0;
  font-size: 0;
  color: var(--color-text-dimmed);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.1s ease;
  transition-property: opacity, box-shadow, background-color;
  text-align: center;
}
.avatar-upload-remove svg {
  display: inline-block;
}

.avatar-upload-wrap:hover .avatar-upload-remove {
  opacity: 1;
}

.avatar-upload-remove:active {
  opacity: 1;
  background: var(--color-separator);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.uploadphoto__input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  font-size: 0;
}

.uploadphoto__error {
  color: var(--color-error);
}

.modal-wrap:deep(.modal) {
  width: 593px;
}

.modal-title {
  margin: 0 0 16px;
  font-weight: 900;
  font-size: 24px;
  line-height: 32px;
  text-transform: uppercase;
}

.button-row {
  margin-top: 32px;
  gap: 30px;
  display: flex;
  & > * {
    flex: 1;
  }
}

:deep(.vue-advanced-cropper__background) {
  background: var(--color-bg-secondary);
}
:deep(.vue-advanced-cropper__background),
:deep(.vue-advanced-cropper__foreground),
:deep(.vue-advanced-cropper__image-wrapper) {
  border-radius: 12px;
}

@media (min-width: 400px) {
  .button-row {
    display: flex;
  }

  .button-row > * + * {
    margin-top: 0;
    margin-left: 12px;
  }
}

.avatar-upload-hint {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  text-align: center;
}

.avatar-upload-hint__tint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-secondary);
}

.avatar-upload-hint__content {
  position: relative;
}

.avatar-upload-hint--shy {
  opacity: 0;
  transition: 0.1s opacity ease;
}

.avatar-upload-wrap:hover .avatar-upload-hint--shy {
  opacity: 1;
}

.avatar-upload-hint--shy .avatar-upload-hint__tint {
  opacity: 0.9;
}

.error {
  color: var(--color-error);
  margin-top: 10px;
}
</style>
